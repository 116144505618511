<template lang="pug">
	#Numeros
		ul
			li(v-for="item in contents.content")
				.left
					p.leftTop {{ item.leftTop }}
					p.leftBottom {{ item.leftBottom }}
				.right
					p.rightTop {{ item.rightTop }}
					p.rightMiddle {{ item.rightMiddle }}
					p.rightBottom {{ item.rightBottom }}
					hr
</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: "numeros",
	props,
}
</script>

<style lang="stylus" scoped src="./CoamoEmNumeros.styl"></style>
